import React from "react"
import c from "classnames"

export default (props) => (
  <svg
    className={c(
      "absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2 z-minus",
      props.className
    )}
    width="404"
    height="404"
    fill="none"
    viewBox="0 0 404 404"
    role="img"
    aria-labelledby="svg-workcation"
  >
    <defs>
      <pattern
        id="ad119f34-7694-4c31-947f-5c9d249b21f3"
        x="0"
        y="0"
        width="20"
        height="20"
        patternUnits="userSpaceOnUse"
      >
        <rect
          x="0"
          y="0"
          width="4"
          height="4"
          className="text-gray-200"
          fill="currentColor"
        />
      </pattern>
    </defs>
    <rect
      width="404"
      height="404"
      fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
    />
  </svg>
)
