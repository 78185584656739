// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import './layout.css';

const Nav = () => {
  return (
    <nav className="hidden md:block sm:h-10 md:justify-center">
      <div className="hidden md:flex md:space-x-10 flex flex-row justify-around mx-40 lg:mx-56 xl:mx-64">
        <AnchorLink to="/#features" title="Features">
          <span
            id="top-nav-features-link"
            className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            Features
          </span>
        </AnchorLink>
        <AnchorLink to="/#download" title="Download">
          <span
            id="top-nav-download-link"
            className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            Download
          </span>
        </AnchorLink>
        <AnchorLink to="/#testimonials" title="Testimonials">
          <span
            id="top-nav-testimonials-link"
            className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            Testimonials
          </span>
        </AnchorLink>
        <AnchorLink to="/#team" title="Team">
          <span
            id="top-nav-team-link"
            className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            Team
          </span>
        </AnchorLink>
        <AnchorLink to="/#faq" title="FAQ">
          <span
            id="top-nav-faq-link"
            className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            FAQ
          </span>
        </AnchorLink>
        <a href="/media-pack" title="FAQ">
          <span
            id="top-nav-faq-link"
            className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
          >
            Media
          </span>
        </a>
      </div>
    </nav>
  );
};

Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
